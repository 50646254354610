var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","flat":"","color":"primary","dark":"","prominent":"","hide-on-scroll":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.back}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(_vm.flatmate.name)?_c('div',{staticClass:"absolute-toolbar-content d-flex flex-column justify-space-between my-3"},[_c('div',{staticClass:"mx-10 text-truncate text-center text-h6 d-flex flex-column align-center"},[_c('Avatar',{attrs:{"user":_vm.flatmate,"border":""}}),_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.flatmate.name))])],1)]):_vm._e()],1),_c('v-main',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-dialog',{ref:"endDateDialog",attrs:{"return-value":_vm.moveOutDate,"width":"290px"},on:{"update:returnValue":function($event){_vm.moveOutDate=$event},"update:return-value":function($event){_vm.moveOutDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Enter the move-out date","prepend-icon":"mdi-calendar","readonly":"","required":"","hide-details":"auto"},model:{value:(_vm.moveOutDate),callback:function ($$v) {_vm.moveOutDate=$$v},expression:"moveOutDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.flatmate.startDate},on:{"input":function($event){return _vm.$refs.endDateDialog.save(_vm.moveOutDate)}},model:{value:(_vm.moveOutDate),callback:function ($$v) {_vm.moveOutDate=$$v},expression:"moveOutDate"}})],1)],1)],1)],1),(_vm.moveOutDate)?_c('div',[_c('v-divider'),(_vm.flat.items)?_c('FlatmateDebtCalculator',{attrs:{"flat":_vm.flat,"flatmate":_vm.flatmate,"date":_vm.moveOutDate},scopedSlots:_vm._u([{key:"descriptionText",fn:function(ref){
var totalDebt = ref.totalDebt;
return [_vm._v(" When "+_vm._s(_vm.flatmate.name)+" moves out on "+_vm._s(_vm._f("humanReadable")(_vm.moveOutDate))+", they should get back a total of "),_c('strong',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm._f("round")(totalDebt)))]),_vm._v(" CHF ")]}},{key:"balanceRowText",fn:function(ref){
var balance = ref.balance;
return [_vm._v(" "+_vm._s(balance.flatmate.name)+" pays "),_c('strong',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm._f("round")(balance.share))+" ")]),_vm._v(" CHF ")]}}],null,false,213016352),model:{value:(_vm.calculatorData),callback:function ($$v) {_vm.calculatorData=$$v},expression:"calculatorData"}}):_vm._e(),_c('v-divider'),_c('div',{staticClass:"d-flex justify-center ma-5"},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.downloadBreakdown}},[_vm._v("1. Download breakdown report")])],1),_c('div',{staticClass:"d-flex justify-center ma-5"},[_c('v-btn',{attrs:{"block":"","color":"warning","disabled":!_vm.isReportDownloaded},on:{"click":_vm.removeFlatmate}},[_vm._v(" 2. Remove flatmate ")])],1)],1):_vm._e()],1),_c('Snackbar',{attrs:{"icon":"mdi-download","text":_vm.snackbarText,"color":"success"},model:{value:(_vm.isSnackbarVisible),callback:function ($$v) {_vm.isSnackbarVisible=$$v},expression:"isSnackbarVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }